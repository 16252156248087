/** @jsx jsx */

import { jsx, Container } from "theme-ui"

import BackgroundImage from "gatsby-background-image"

export default ({ title, image, position }) => {
  return (
    <BackgroundImage
      Tag="section"
      fluid={[`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`, image]}
      sx={{
        display: `flex`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `${position}`,
        textAlign: "center",
        height: [`175px`, `450px`],
      }}
    >
      <Container
        sx={{
          margin: `auto`,
          marginLeft: [`30px`, `auto`],
          //width: 300,
          textAlign: ["left", "center"],
          justifyContent: ["left", "center"],
        }}
      >
        <h1
          sx={{
            fontSize: [40, 50],
            width: [`200px`, `100%`],
            lineHeight: `40px`,
            mb: `2`,
            color: `white`,
          }}
        >
          <span
            sx={{
              position: `relative`,
              zIndex: 0,
              // "&::before": {
              //   content: `""`,
              //   position: `absolute`,
              //   top: `2px`,
              //   left: 0,
              //   bottom: 0,
              //   right: 0,
              //   zIndex: `-1`,
              //   backgroundColor: `#2884d2`,
              //   transform: `skewX(-15deg) skewY(-1.5deg)`,
              //   margin: `12px -6px 2px -4px`,
              //   borderRadius: `8px 12px 12px 20px`,
              // },
            }}
          >
            {title}
          </span>
        </h1>
      </Container>
    </BackgroundImage>
  )
}
