/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics"

import Img from "gatsby-image"
import ShareButtons from "../components/share-buttons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Social from "../components/social"
import Banner from "../components/banner"

const IndexPage = ({ data }) => (
  <Layout title={false}>
    <SEO
      title="Chi Sono"
      description="Sono Arturo Lorenzoni, da luglio 2017 a luglio 2020 vicesindaco di
      Padova. Insegno Economia dell’Energia ed
      Electricity Market Economics alla Scuola di Ingegneria Industriale
      dell’Università di Padova."
    />
    <Banner
      // title="Lorenzoni Presidente"
      image={data.banner.childImageSharp.fluid}
      position={`50% 50%`}
    />
    {/* <Iframe url={`https://www.youtube-nocookie.com/embed/cdo9oYnKHgE`} /> */}
    <h1
      sx={{
        textTransform: `uppercase`,
        my: 3,
        fontSize: [40, 50],
        textAlign: `center`,
      }}
    >
      Arturo <span sx={{ color: `secondary` }}>Lorenzoni</span>
    </h1>
    <Social />

    <Container sx={{ maxWidth: 680, p: [4, null], textAlign: `justify` }}>
      <p>
        Abbiamo bisogno di un cambio di paradigma radicale. Per tornare a
        crescere e ad essere protagonisti in Italia e in Europa il Veneto deve
        attrarre giovani, donne e uomini di talento. Io non mi rassegno al
        declino, allo spopolamento demografico, imprenditoriale, sociale. Per
        invertire la rotta puntiamo su innovazione, sostenibilità ambientale,
        riconversione pubblica e universale della sanità e possibilità di
        spostamento rapido, sicuro ed economico.
      </p>
      <p>
        Sono Arturo Lorenzoni, da luglio 2017 a luglio 2020 vicesindaco di
        Padova con le deleghe a politiche del territorio e sviluppo urbano
        sostenibile, università, mobilità e viabilità, Agenda 21, programma
        agenda digitale, servizi informatici e telematici, edilizia privata,
        accessibilità e vita indipendente. Insegno Economia dell’Energia ed
        Electricity Market Economics alla Scuola di Ingegneria Industriale
        dell’Università di Padova. Nel 2013 ho ottenuto l'abilitazione al ruolo
        di professore ordinario nel settore scientifico Economia Applicata.
      </p>
    </Container>
    <Img
      sx={{ margin: [null, `auto`], maxWidth: `960px` }}
      fluid={data.piazza.childImageSharp.fluid}
      alt="Arturo Lorenzoni - Piazza delle Erbe, Padova"
    />
    <div
      sx={{
        mt: 2,
        textAlign: `center`,
        fontSize: `12px`,
        color: `textMuted`,
      }}
    >
      Piazza delle Erbe, Padova (2017)
    </div>
    <Container sx={{ maxWidth: 680, p: [4, null], textAlign: `justify` }}>
      <p>
        Ingegnere elettrotecnico (1991), Dottore di Ricerca in Energetica
        (1995), nel 1992 ho conseguito il Master in Economia dell'Energia e
        dell'Ambiente presso la Scuola Superiore Enrico Mattei E.N.I. e nel 1993
        – 1994 sono stato "Study Fellow" alla University of Sussex a Brighton.
      </p>
      <p>
        I miei interessi scientifici comprendono l’economia applicata al settore
        dell’energia, la regolamentazione del settore, lo sviluppo delle fonti
        rinnovabili di energia e dell’efficienza energetica.
      </p>
      <p>
        Dal 1993 collaboro anche con l'Istituto di Economia e Politica
        dell’Energia e dell’Ambiente (IEFE) dell'Università Bocconi di Milano
        svolgendo attività di ricerca nel campo dell’economia e della
        regolamentazione. Coordino progetti di ricerca a livello nazionale e
        internazionale, partecipo e coordino numerosi progetti comunitari (Ue)
        nel settore delle politiche energetiche.
      </p>
      <p>
        <a
          href={`/assets/pdf/cv.pdf`}
          onClick={() => {
            trackCustomEvent({
              category: "CV",
              action: "Click",
            })
          }}
        >
          Curriculum Vitae
        </a>
      </p>
      <p>
        <a
          href={`/assets/pdf/casellario-giudiziale.pdf`}
          onClick={() => {
            trackCustomEvent({
              category: "Casellario",
              action: "Click",
            })
          }}
        >
          Casellario Giudiziale
        </a>
      </p>
      <OutboundLink
        href="https://www.youtube.com/watch?v=cdo9oYnKHgE"
        onClick={() => {
          trackCustomEvent({
            category: "ViewVideo",
            action: "Click",
          })
        }}
      >
        <p>Arturo Lorenzoni. Più di un candidato. (video)</p>
      </OutboundLink>
      <p>
        <Link to="/un-nuovo-inizio/">
          Il mio saluto alla città di Padova al termine del mandato
        </Link>
      </p>
      <p>Il testo di alcuni dei miei interventi istituzionali:</p>
      <ul>
        <li>
          <Link to="/1-maggio-2020/">Primo Maggio 2020</Link>
        </li>
        <li>
          <Link to="/giornata-memoria-2019/">Giornata della Memoria 2019</Link>
        </li>
        <li>
          <Link to="/25-aprile-2018/">25 Aprile 2018</Link>
        </li>
      </ul>
      <ShareButtons
        title="Chi Sono"
        url="https://www.arturolorenzoni.it/chi-sono/"
      />
    </Container>
  </Layout>
)

export default IndexPage

export const data = graphql`
  query {
    banner: file(relativePath: { eq: "DSC_0696.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    piazza: file(relativePath: { eq: "IMG_9805.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
